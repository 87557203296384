import axios from 'axios'
const defaultState = {
  paginationParams: {
    l: 20, // itemSize
    lo: 0, // page
    state: null,
    sort: null,
    sortDirection: null,
    searchTerm: null
  },
  data: {
    total: 0,
    items: [],
    stateCounts: undefined
  }
}

const userProfileStore = {
  namespaced: true,
  state: Object.assign(defaultState),
  getters: {
    paginationParams: (state: any) => state.paginationParams,
    data: (state: any) => state.data
  },
  mutations: {
    setPaginationParams (state: any, payload: any) {
      state.paginationParams = payload
    },
    setData (state: any, payload: any) {
      state.data = payload
    },
    reset (state: any) {
      state = Object.assign(defaultState)
    }
  },
  actions: {
    async fetchUserProfiles ({ commit }: any, params: any) {
      const result = await axios.get('/api/userprofile', { params: params })
      if (result && result.data) {
        commit('setPaginationParams', params)
        commit('setData', result.data)
        return result
      }
    }
  }
}

export default userProfileStore
