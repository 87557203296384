import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import './plugins/globalComponents'
import './plugins/globalFunctions'
import './plugins/vee-validate'
import { initFilters } from './plugins/filters'
import VuetifyConfirm from 'vuetify-confirm'
import { checkIfUserHasAnyRole, initUserService } from './shared/services/user.service'
import PortalVue from 'portal-vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

initFilters()
initUserService(store) // account + auth

Vue.config.productionTip = false

Vue.use(VuetifyConfirm, {
  vuetify,
})

Vue.use(PortalVue)

const waitForKeycloak = (callback: any) => {
  const authLoading = store.getters.authLoading
  if (authLoading) {
    setTimeout(() => {
      waitForKeycloak(callback)
    }, 100)
  } else {
    callback()
  }
}

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/not-found')
  }

  // once keycloak is initialized and checked if user is logged in we can check for roles
  // until that point the appView is showing a loading circle -> this avoids unnecesary redirect on refreshing the page
  waitForKeycloak(() => {
    if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
      checkIfUserHasAnyRole(to.meta.authorities).then((value: any) => {
        if (!value) {
          sessionStorage.setItem('requested-url', to.fullPath)
          next('/forbidden')
        }
      })
    }
  })

  next()
})

if (!window.location.href.includes('localhost')) {
  Sentry.init({
    Vue,
    dsn: 'https://68fa11508f1b4796a57fbf98dc440ac2@sentry.seadev-studios.com/10',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'admin.sisers.seadev-studios.com', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // Recommendations for prod are 0.2-0.5
    tracesSampleRate: 0.2,
  })
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
