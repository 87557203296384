import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@/styles/overrides.sass'

import VuetifyToast from 'vuetify-toast-snackbar'
import { VBtn, VIcon, VSnackbar } from 'vuetify/lib'

// following is required for vuetifyToast
// but maybe check if it destroys vuetifys treeshaking somehow
Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

const theme = {
  primary: '#7b1f37',
  secondary: '#f5bed5',
  accent: '#ed95b9',
  info: '#5c1628',
  success: '#4CAF50',
  warning: '#FFC107'
}
// cool orange ;)
// primary: '#ff8b2f',

const VuetifyObj = new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
    // dark: true
  }
})

Vue.use(VuetifyToast, {
  $vuetify: VuetifyObj.framework,
  x: 'center',
  y: 'bottom',
  color: 'darkgrey',
  icon: 'mdi-alert-circle-outline',
  iconColor: '', // default
  classes: ['flex-snackbar'],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: true,
  closeText: '',
  closeIcon: 'mdi-check',
  closeColor: 'success',
  slot: [], // default
  shorts: {
    error: {
      closeIcon: 'mdi-close',
      closeColor: 'error'
    }
  },
  property: '$toast' // default
})

export default VuetifyObj
