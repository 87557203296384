import { localize } from 'vee-validate'
import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

import validationMessages from 'vee-validate/dist/locale/de.json'
import validationMessagesEN from 'vee-validate/dist/locale/en.json'

Vue.use(VueI18n)

// VeeValidate
// if we add more languages in the future we should wrap that inside a function
localize('de', validationMessages)

export function changeLanguage (lang: string) {
  i18n.locale = lang
  if (lang === 'de') {
    localize('de', validationMessages)
  } else if (lang === 'en') {
    localize('en', validationMessagesEN)
  }
}

function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: loadLocaleMessages()
})

export default i18n
