import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line camelcase
import { email, max, min, min_value, numeric, required } from 'vee-validate/dist/rules'
import { regexEUNumber } from '@/shared/helpers'

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', required)
extend('min_value', min_value)

// https://stackoverflow.com/questions/59156440/how-to-validate-decimal-value-in-vee-validate-3-0-version
extend('decimal', async (value) => {
  return regexEUNumber(value) ? true : 'dialog.enter-valid-eu-number-format'
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

/// //// EXAMPLES: If we need custom validation checks again

// EXAMPLE: async call
// extend('checkusername', async (value) => {
// const usernameCheck: any = await userService.checkIfUsernameExists(value)
// return usernameCheck.status === 200 ? true : 'dialog.username-is-already-used'
// })

// EXAMPLE: custom i18n message
// extend('checkconsent', async (value) => {
//   return value ? true : 'dialog.required-to-complete-registration'
// })

// EXAMPLE: regex
// extend('constructionyear', async (value) => {
//   return regexNumbersAndSpecialChars(value) ? true : 'dialog.only-use-numbers-with-or'
// })
