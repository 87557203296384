import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/views/PagesView.vue'),
    children: [
      {
        name: 'homepage',
        path: '',
        component: () => import('@/views/pages/HomePage.vue')
      }
    ]
  },
  {
    path: '/app',
    component: () => import('@/views/AppView.vue'),
    children: [
      {
        name: 'dashboard',
        path: '',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/Dashboard.vue')
      },
      {
        name: 'videos',
        path: 'videos',
        meta: { authorities: ['user', 'admin'], keepAlive: true },
        component: () => import('@/views/app/VideoList.vue')
      },
      {
        name: 'user-profile',
        path: 'profile',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/UserProfile.vue')
      },
      {
        name: 'tables',
        path: 'tables',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/Tables.vue')
      },
      // {
      //   name: 'Wizards',
      //   path: 'wizards',
      //   meta: { authorities: ['user', 'admin'] },
      //   component: () => import('@/views/app/Wizard.vue')
      // },
      // {
      //   name: 'Calendar',
      //   path: 'calendar',
      //   meta: { authorities: ['user', 'admin'] },
      //   component: () => import('@/views/app/Calendar.vue')
      // },
      {
        name: 'categories',
        path: 'categories',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/Categories.vue')
      },
      {
        name: 'tags',
        path: 'tags',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/Tags.vue')
      },
      {
        name: 'challenges',
        path: 'challenges',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/Challenges.vue')
      },
      {
        name: 'challenge',
        path: 'challenges/:id',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/ChallengeEdit.vue')
      },
      {
        name: 'stretching-plans',
        path: 'stretching-plans',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/StretchingPlans.vue')
      },
      {
        name: 'stretching-plan',
        path: 'stretching-plans/:id',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/StretchingPlanEdit.vue')
      },
      {
        name: 'news',
        path: 'news',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/News.vue')
      },
      {
        name: 'news-livestretching',
        path: 'news-livestretching',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/NewsLivestretching.vue')
      },
      {
        name: 'motivation-text',
        path: 'motd',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/MotivationText.vue')
      },
      {
        name: 'push-notifications',
        path: 'pushnotifications',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/PushNotifications.vue')
      },
      {
        name: 'system-settings',
        path: 'settings',
        meta: { authorities: ['user', 'admin'] },
        component: () => import('@/views/app/SystemSettings.vue')
      },
      {
        path: '*',
        component: () => import('@/views/shared/ErrorPage.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/PagesView.vue'),
    children: [
      {
        name: 'error-page',
        path: '',
        component: () => import('@/views/shared/ErrorPage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
