<template>
  <v-card v-bind="$attrs" class="v-card--material pa-2 mt-4">
    <v-card-title class="align-start">
      <v-sheet
        :color="color"
        :width="fullHeader ? '100%' : undefined"
        class="mt-n12 transition-swing v-card--material__sheet"
        max-width="100%"
        rounded
      >
        <v-theme-provider v-if="hasHeading" dark>
          <div v-if="icon" :class="iconSmall ? 'pa-4' : 'pa-5'">
            <v-icon :large="!iconSmall" v-text="icon" />
          </div>

          <slot name="heading" />

          <div v-if="heading" class="text-h4 white--text pa-7 v-card--material__title">
            {{ heading }}
          </div>
        </v-theme-provider>
      </v-sheet>

      <div v-if="hasTitle" class="text-h4 pl-4 v-card--material__title">
        <slot name="title" />

        <template v-if="title">
          {{ title }}
        </template>

        <div class="text-subtitle-1 mb-n2">
          <slot name="subtitle" />

          <template v-if="subtitle">
            {{ subtitle }}
          </template>
        </div>
      </div>
    </v-card-title>

    <div class="px-2">
      <slot />
    </div>

    <template v-if="$slots.actions">
      <v-divider class="mt-2 mx-4" />

      <v-card-actions class="px-4 text-caption grey--text">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'MaterialCard',

  props: {
    color: String,
    fullHeader: Boolean,
    heading: String,
    icon: String,
    iconSmall: Boolean,
    reveal: Boolean,
    subtitle: String,
    title: String
  },

  computed: {
    hasHeading () {
      return !!(this.icon || this.heading || this.$slots.heading)
    },
    hasTitle () {
      return !!(this.title || this.subtitle || this.$slots.title || this.$slots.subtitle)
    }
  }
}
</script>

<style lang="sass">
.v-card.v-card--material
  > .v-card__title
    > .v-card--material__title
      flex: 1 1 auto
      word-break: break-word
</style>
