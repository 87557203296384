import { IUser } from '../models/user.model'
import axios, { AxiosResponse } from 'axios'
import Keycloak from 'keycloak-js'
import Vue from 'vue'
import store from '@/store'

class UserService {
  keycloak = Keycloak({
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: 'web_app'
  })

  initKeycloak () {
    this.keycloak.onTokenExpired = () => {
      // console.log('token expired', this.keycloak.token)
      this.keycloak
        .updateToken(30)
        .then(() => {
          if (this.keycloak.token) {
            store.commit('authenticated', this.keycloak.tokenParsed)

            // add auth token globally to axios headers
            axios.defaults.headers.common.accept = 'application/json' // for POST requests
            axios.defaults.headers.common.Authorization = 'Bearer ' + this.keycloak.token // for all requests
          } else {
            // user is not logged in
            store.commit('logout')
          }
        })
        .catch((e) => {
          console.log(e)
          console.log('error updating keycloak token')
          store.commit('logout')
        })
    }
    return this.keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
    })
  }

  login () {
    // info: .then() here does not seem to work (probably because of the iframe/page-change)
    this.keycloak.login()
  }

  logout () {
    // info: .then() here does not seem to work (probably because of the iframe/page-change)
    this.keycloak.logout({ redirectUri: window.location.origin })
  }

  updateToken () {
    this.keycloak
      .updateToken(30)
      .then(function () {
        // loadData()
      })
      .catch(function () {
        alert('Failed to refresh token')
      })
  }

  getMyself () {
    //
  }

  updateUser (user: IUser) {
    // depending on the use-case (change password / app-settings / change lang)
    // this would call the keycloak or the api
  }

  getAllUsersPaginated (query: any) {
    return store.dispatch('userStore/getList', query)
  }

  checkVersion () {
    const VERSION = 'latestFetchedVersion'
    return axios
      .get('/api/version')
      .then((response: AxiosResponse) => {
        if (response && response.data && response.data.version) {
          const currentVersion: string = response.data.version
          const latestFetchedVersion: string | null = localStorage.getItem(VERSION)

          if (latestFetchedVersion !== null) {
            if (latestFetchedVersion !== currentVersion) {
              localStorage.setItem(VERSION, currentVersion)
              location.reload()
            }
          } else {
            localStorage.setItem(VERSION, currentVersion)
          }
        }
      })
      .catch((e) => {
        console.error('updateUser() failed', e)
      })
  }

  getUserImage (id: string) {
    return axios.get(`/api/file/${id}`).catch((e: any) => {
      console.error('getUserImage() failed', e)
      return e.response
    })
  }
}

export function checkIfUserHasAnyRole (authorities: any): Promise<boolean> {
  if (typeof authorities === 'string') {
    authorities = [authorities]
  }

  const authenticated = store.getters.authenticated
  const userAccount = store.getters.account
  if (!authenticated || !userAccount || !userAccount.roles) {
    return Promise.resolve(false)
  }

  for (const authority of authorities) {
    if (userHasRole(authority)) {
      return Promise.resolve(true)
    }
  }

  return Promise.resolve(false)
}

export function userHasRole (role: string): boolean {
  const userAccount = store.getters.account
  if (!userAccount || !userAccount.roles) {
    return false
  }
  return userAccount.roles.includes(role)
}

declare module 'vue/types/vue' {
  // Declare the type so we can use it in 'ts' (see AppBar)
  interface Vue {
    $userService: any
  }
}
export function initUserService (store: any): any {
  const service = new UserService()
  Vue.prototype.$userService = service
  service
    .initKeycloak()
    .then(() => {
      console.log('keycloak initialized')
      if (service.keycloak.token) {
        store.commit('authenticated', service.keycloak.tokenParsed)

        // add auth token globally to axios headers
        axios.defaults.headers.common.accept = 'application/json' // for POST requests
        axios.defaults.headers.common.Authorization = 'Bearer ' + service.keycloak.token // for all requests
      } else {
        // user is not logged in
        store.commit('logout')
      }
    })
    .catch((e) => {
      console.log(e)
      console.log('init keycloak failed')
    })
  return service
}
